<template>
  <div class="row m-0 align-items-center justify-content-center relative">
    <div class="col-12 col-md-auto">
      <div class="row justify-content-md-center align-items-center">
        <div class="col-12 col-md-auto mb-4 mb-md-0">
          <div class="row h-100 align-items-center justify-content-center">
            <div class="col-auto col-md medium green-text text-end line1">
              FILTER<div class="d-none d-md-block">DONATIONS</div><span class="d-inline-block d-md-none">&nbsp;DONATIONS</span>
            </div>
            <div class="col-auto col-md-auto p-0">
              <IconFilter color="green" size="size28" class="d-none d-md-block" />
              <IconFilter color="green" size="size16" class="d-md-none" />
            </div>
          </div>
        </div>
        <div class="col-6 col-md-auto px-1">
          <FilterSelectDonor
            id="donor"
            :options="donorOptions"
            placeholder="Donor"
            @applyfilter="filterDonorType"
            @clearfilter="filterDonorTypeClear"
            :theFilter="theFilter"
            title="Donor"
          />
        </div>
        <div class="col-6 col-md-auto px-1">
          <FilterSelectDate
            id="date"
            :options="dateOptions"
            placeholder="Donation Date"
            @applyfilter="filterDate"
            @clearfilter="filterDateClear"
            title="Donation Date"
          />
        </div>
        <div class="col-6 col-md-auto px-1">
          <FilterSelect
            id="price"
            :options="priceRange"
            placeholder="Amount"
            @applyfilter="filterPrice"
            @clearfilter="filterPriceClear"
            title="Filter Price"
          />
        </div>
        <div class="col-6 col-md-auto px-1">
          <FilterSelect
            id="donationType"
            :options="donationType"
            placeholder="Donation Type"
            @applyfilter="filterDonationType"
            @clearfilter="filterDonationTypeClear"
            title="Filter Donation Type"
          />
        </div>
        <div class="col-6 col-md-auto px-1">
          <ButtonDropDown placeholder="Print" isAll="true" :disabled="selectedItems.length < 1" @doSelected="printSelected" @doAll="printAll" @doRange="printRange">
            <IconPrint size="size16" />
          </ButtonDropDown>
        </div>
        <div class="col-6 col-md-auto px-1">
          <ButtonDropDown placeholder="Download" isAll="true" :disabled="selectedItems.length < 1" @doSelected="downloadSelected" @doAll="downloadAll" @doRange="downloadRange">
            <IconDownload size="size16" />
          </ButtonDropDown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    FilterSelect: defineAsyncComponent(() => import('../components/FilterSelect.vue')),
    FilterSelectDate: defineAsyncComponent(() => import('../components/FilterSelectDate.vue')),
    FilterSelectDonor: defineAsyncComponent(() => import('../components/FilterSelectDonor.vue')),
    ButtonDropDown: defineAsyncComponent(() => import('../components/ButtonDropDown.vue')),
    IconFilter: defineAsyncComponent(() => import('../components/icons/IconFilter.vue')),
    IconDownload: defineAsyncComponent(() => import('../components/icons/IconDownload.vue')),
    IconPrint: defineAsyncComponent(() => import('../components/icons/IconPrint.vue'))
  },
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  props: ['selectedItems', 'theFilter'],
  watch: {
    theFilter () {
      if (this.theFilter) {
        const arr = ['gro' + this.theFilter]
        this.filterDonorType(arr)
      }
    }
  },
  computed: {
    ...mapGetters([
      'projects',
      'user',
      'organisations',
      'groups',
      'transactions',
      'projectTypes',
      'donationTypes'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    theTransactions () {
      if (this.isUK) {
        return this.transactions.filter(transaction => {
          if (transaction.transactionStatus.transactionStatusID !== 4 && transaction.transactionStatus.transactionStatusID !== 5 && transaction.transactionStatus.transactionStatusID !== 12 && transaction.transactionStatus.transactionStatusID !== 14) {
            return false
          }
          return true
        })
      } else {
        return this.transactions
      }
    },
    donorOptions () {
      const arr = this.theTransactions
      const result = {
        donor: {},
        organisations: {},
        groups: {}
      }
      const donor = new Map()
      const organisations = new Map()
      const groups = new Map()
      if (arr.length > 0) {
        arr.forEach(el => {
          if (!el.organisationID && !el.donorGroupID) {
            if (el.donationMadeBy) {
              const item = donor.get('don' + el.donationMadeBy.userID) || { id: 'don' + el.donationMadeBy.userID, name: el.donationMadeBy.firstName + ' ' + el.donationMadeBy.lastName, count: 0 }
              item.count++
              donor.set(item.id, item)
            } else {
              const item = donor.get('don' + el.user.userID) || { id: 'don' + el.user.userID, name: el.user.firstName + ' ' + el.user.lastName, count: 0 }
              item.count++
              donor.set(item.id, item)
            }
          } else if (el.organisationID) {
            const org = this.organisations.find(organisation => organisation.organisationID === el.organisationID)
            const orgName = org.registeredName
            const item = organisations.get('org' + el.organisationID) || { id: 'org' + el.organisationID, name: orgName, count: 0 }
            item.count++
            organisations.set(item.id, item)
          } else if (el.donorGroupID) {
            const group = this.groups.find(group => group.donorGroupID === el.donorGroupID)
            var groupName = 'Unnamed Group'
            if (group) {
              groupName = group.donorGroupName
            }
            const item = groups.get('gro' + el.donorGroupID) || { id: 'gro' + el.donorGroupID, name: groupName, count: 0 }
            item.count++
            groups.set(item.id, item)
          }
        })
      }
      const donorSortedAsc = new Map([...donor].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      const organisationsSortedAsc = new Map([...organisations].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      const groupsSortedAsc = new Map([...groups].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      result.donor = donorSortedAsc
      result.organisations = organisationsSortedAsc
      result.groups = groupsSortedAsc
      return result
    },
    dateOptions () {
      const arr = [
        {
          id: 1,
          description: 'Choose a Date'
        }, {
          id: 2,
          description: 'Date Range'
        }
      ]
      const result = new Map()
      arr.forEach(el => {
        const item = result.get(el.id) || { id: el.id, name: el.description, count: 0 }
        item.count++
        result.set(item.id, item)
      })
      return result
    },
    projectCategories () {
      const arr = this.projects
      const result = new Map()
      arr.forEach(el => {
        const item = result.get(el.projectType.id) || { id: el.projectType.id, name: el.projectType.name, count: 0 }
        item.count++
        result.set(item.id, item)
      })
      return result
    },
    donationType () {
      const arr = this.theTransactions
      const result = new Map()
      arr.forEach(el => {
        el.donationList.forEach(donation => {
          const item = result.get(donation.donationType.donationTypeID) || { id: donation.donationType.donationTypeID, name: donation.donationType.description, count: 0 }
          item.count++
          result.set(item.id, item)
        })
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].name > b[1].name ? 1 : -1)))
      return sortedAsc
    },
    priceRange () {
      const arr = this.transactions
      const result = new Map()
      arr.forEach(el => {
        if (el.estimatedValue < 50) {
          const item = result.get(el.estimatedValue) || { id: '0-50', name: 'Up to ' + this.defaultCurrency + '50', sortOrder: 'a' }
          result.set(item.id, item)
        } else if (el.estimatedValue < 150 && el.estimatedValue >= 50) {
          const item = result.get(el.estimatedValue) || { id: '50-150', name: this.defaultCurrency + '50 to ' + this.defaultCurrency + '150', sortOrder: 'b' }
          result.set(item.id, item)
        } else if (el.estimatedValue < 500 && el.estimatedValue >= 150) {
          const item = result.get(el.cost) || { id: '150-500', name: this.defaultCurrency + '150 to ' + this.defaultCurrency + '500', sortOrder: 'c' }
          result.set(item.id, item)
        } else if (el.estimatedValue < 1000 && el.estimatedValue >= 500) {
          const item = result.get(el.cost) || { id: '500-1000', name: this.defaultCurrency + '500 to ' + this.defaultCurrency + '1000', sortOrder: 'd' }
          result.set(item.id, item)
        } else if (el.estimatedValue >= 1000) {
          const item = result.get(el.estimatedValue) || { id: '1000-1001', name: '' + this.defaultCurrency + '1000 & above', sortOrder: 'e' }
          result.set(item.id, item)
        }
      })
      const sortedAsc = new Map([...result].sort((a, b) => (a[1].sortOrder > b[1].sortOrder ? 1 : -1)))
      return sortedAsc
    }
  },
  methods: {
    filterDate (val) {
      const handler = {
        get (target, property) {
          return target[property]
        }
      }
      const proxyVal = new Proxy(val, handler)
      // if (Object.keys(this.selectedChildProject).length > 0) {
      //   this.donation.projectId = this.selectedChildProject.adminProjectId
      // }
      if (proxyVal.startDate && proxyVal.endDate) {
        this.$emit('updateDate', val)
      } else {
        this.$emit('updateDate', null)
      }
    },
    filterDateClear () {
      this.$emit('updateDate', null)
    },
    filterPrice (val) {
      if (val.length > 0) {
        this.$emit('updatePrice', val)
      } else {
        this.$emit('updatePrice', null)
      }
    },
    filterPriceClear () {
      this.$emit('updatePrice', null)
    },
    filterDonorType (val) {
      if (val.length > 0) {
        this.$emit('updateDonor', val)
      } else {
        this.$emit('updateDonor', null)
      }
    },
    filterDonorTypeClear () {
      this.$emit('updateDonor', null)
    },
    filterDonationType (val) {
      if (val.length > 0) {
        this.$emit('updateDonationType', val)
      } else {
        this.$emit('updateDonationType', null)
      }
    },
    filterDonationTypeClear () {
      this.$emit('updateDonationType', null)
    },
    printSelected () {
      this.$emit('printSelected')
    },
    printRange (val) {
      this.$emit('printRange', val)
    },
    printAll () {
      this.$emit('printAll')
    },
    downloadAll (val) {
      this.$emit('downloadAll')
    },
    downloadSelected () {
      this.$emit('downloadSelected')
    },
    downloadRange (val) {
      this.$emit('downloadRange', val)
    }
  }
}
</script>

<style scoped>
.line1 {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--green-color);
  line-height: 1;
}
</style>
